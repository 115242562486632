import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import QRCode from "qrcode.react";

const API_BASE_HTTP_URL = process.env.REACT_APP_API_BASE_HTTP_URL;
const API_BASE_WS_URL = process.env.REACT_APP_API_BASE_WS_URL;

const broadcastCommand = (ws: WebSocket | null, cmd: "clear-all" | "switch", id?: number, value?: boolean) => {
  console.log(`CMD: ${cmd}, ID: ${id}, VALUE: ${value}`);
  if (ws !== null) {
    ws.send(JSON.stringify({
      cmd,
      id,
      value,
    }));
  }
}

const App = () => <Router>
  <Switch>
    <Route path="/rooms/:id" component={CueControls} />
    <Route path="/" component={Home}>
    </Route>
  </Switch>
</Router>

class Home extends React.Component {
  handleSubmit = () => {
    // evt.preventDefault();
    fetch(`${API_BASE_HTTP_URL}/rooms`, {
      method: "POST",
    })
      .then(res => res.json())
      .then(({ id }) => {
        window.location.href = `/rooms/${id}`;
      })
      .catch(window.alert);
  }

  render() {
    return (
      <>
        <div className="button go" onMouseDown={this.handleSubmit}>
          NEW SHOW
        </div>
        <p>
          Press NEW SHOW to get started!
        </p>
        <hr />
        <p>
          Note well: This is a DEMO.
        </p>
        <p>
          To provide feedback, please use <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/Wc5KDHmoTR6hoYAcA">the feedback form</a>.
          If you'd like to join the mailing list to receive product updates and announcements, <a rel="noreferrer noopener" target="_blank" href="https://forms.gle/158S4AcjqfLgkd1j6">click here</a>.
        </p>
        <p>
          <strong>The system clears and sleeps periodically
          when there are no active shows. If the DEMO isn't working, please
          go to <a target="_blank" rel="noopener noreferrer" href="https://cue.rwoll.com">https://cue.rwoll.com</a>.
          in 5 minutes and start again.</strong>
        </p>
      </>
    );
  }
}

class CueControls extends React.Component<
  RouteComponentProps<{ id: string; }>,
  { lights: boolean[]; ws: WebSocket | null; }
  > {
  constructor(props: RouteComponentProps<{ id: string; }>) {
    super(props);
    this.state = {
      ws: null,
      lights: [false, false, false, false, false, false, false, false, false, false, false, false]
    };
  }

  handleClear = () => {
    this.setState({
      lights: this.state.lights.map(_ => false),
    }, () => broadcastCommand(this.state.ws, "clear-all"));
  }

  handleSwitch = (id: number, value: boolean) => {
    const lights = this.state.lights.map(v => v);
    lights[id] = value;
    this.setState({
      lights,
    }, () => broadcastCommand(this.state.ws, "switch", id, value));
  }

  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    const ws = new WebSocket(`${API_BASE_WS_URL}/rooms/${id}`);
    this.setState({ ws });
    ws.addEventListener("message", ({ data }) => {
      console.log("RECV >", data);
      this.setState({ lights: JSON.parse(data) });
    });
  }

  render() {
    return (
      <>

        <div className="container">
          <div className="leader">
            <div onMouseDown={this.handleClear} className={`button go ${this.state.lights.filter(v => v).length ? "on" : ""}`}>
              Clear
          </div>
          </div>
          <div className="followers">
            {this.state.lights.map((isOn, idx) => <ButtonColumn clickHandler={() => this.handleSwitch(idx, !isOn)} key={idx} label={`${idx + 1}`} isIlluminated={isOn} />)}
          </div>
        </div>
        <ol>
          <li>
            <p>Share the following URL with your collaborators:</p>
            <p>{window.location.href}</p>
            <p>or scan the following QR Code on your phone:</p>
            <p>
              <QRCode size={300} value={window.location.href} />
            </p>
            <p>or open multiple windows pointed at that link to see what it's like!</p>
            <p>
              <strong>
                Anyone with the link can control your cue lights and see them turn on/off!
              </strong>
            </p>
          </li>
          <li>
            <p>
              Click on a numbered "light" to toggle it on and off.
            </p>
          </li>
          <li>
            <p>
              Click CLEAR to turn all numbered "lights" off at the same time.
            </p>
          </li>
          <li>
            <p>
              Repeat! Everyone with the link should see the same view within
              milliseconds—even if they are a few countries away or on a mobile
              network!
            </p>
          </li>
        </ol>
        <hr />
        <p>
          Note well: This is a DEMO.
        </p>
        <p>
          To provide feedback, please use <a rel="noreferrer noopener" target="_blank" href="https://forms.gle/Wc5KDHmoTR6hoYAcA">the feedback form</a>.
          If you'd like to join the mailing list to receive product updates and announcements, <a rel="noreferrer noopener" target="_blank" href="https://forms.gle/158S4AcjqfLgkd1j6">click here</a>.
        </p>
        <p>
          <strong>The system clears and sleeps periodically
          when there are no active shows. If the DEMO isn't working, please
          go to <a rel="noreferrer noopener" target="_blank" href="https://cue.rwoll.com">https://cue.rwoll.com</a>.
          in 5 minutes and start again.</strong>
        </p>
      </>
    );
  }
}

const ButtonColumn = (props: { clickHandler: () => void; label: string; isIlluminated: boolean; }) => <>
  <div className="follower">
    <div onMouseDown={props.clickHandler} className={`button ${props.isIlluminated ? "on" : ""}`}>{props.label}</div>
    {/* <div className="label">{props.label}</div> */}
  </div>
</>

export default App;
